<!--联盟介绍-->
<template>
  <div class="AllianceIntroduce">
    <div class="left-image">
      <el-image
          class="left-image"
          :src="require('@/assets/images/loge.png')"
          fit="contain" />
    </div>
    <div v-if="this.language === 'zh_CN'" class="right-info p-scroll">
      <p>&emsp;&emsp;世界大健康经济发展联盟，英文简称: WHIEDA，是一家全球性的大健康产业联盟机构。总部位于中国。
        <br>
        &emsp;&emsp;世界大健康经济发展联盟在'一带一路"互联互通的大背景下成立，以a弘扬养生文化，造福人类健康'为宗旨，以全球视野集聚高端资源，以全球智库贼能产业升级，以全球平台惠泽人类健康，致力于为全球大健康产业优秀企业、行业组织、科研机构、业界专家学者搭建交流与合作平台，在遵守各国法律尊重各国文化的基础上，加强以大健康产业为主、其他产业为辅的国际间的产学研合作对接，促进健康产业国际资本投资与流通，发掘区域间大健康市场潜力，加快健康养生理念的广泛传播，推动*全球健康革命”，引领世界大健康产业可持续发展，为提高全人类的健康水平而不俾努力。
        <br>
        &emsp;&emsp;世界大健康经济发展联盟由联盟主席团领导(主席一名、执行主席一名、副主席若干名），下设秘书处、产业经济发展委员会、健康养生教育委员会、产业专家顾问委员会、产业金融服务委员会等专业机构，并设立欧亚大健康经济发展联盟、非洲大健康经济发展联盟、美洲及大洋洲大健康经济发展联盟等区域性分支机构。
      </p>
    </div>
    <div v-else-if="this.language === 'ru_RU'" class="right-info p-scroll">
      <p>
        &emsp;&emsp;Международная Ассоциация «Здоровье и Процветание»  WHIEDA  представляет собой международную организацию в сфере индустрии здравоохранения. Штаб-квартира находится в Китае.
        <br>
        &emsp;&emsp;Руководствуясь инициативой международного сотрудничества «Один пояс, один путь» в развитии культуры здорового образа жизни, Ассоциация WHIEDA стремится создать универсальную бизнес-платформу для сотрудничества и обмена опытом между предприятиями, отраслевыми организациями, научно-исследовательскими институтами, экспертами и учеными в сфере здравоохранения. Также Ассоциация WHIEDA ставит своей главной целью стимулировать приток международных инвестиций в развитие межрегионального сотрудничества в области здравоохранения, тем самым содействуя “глобальной революции в индустрии здоровья. Ассоциация стремится прилагать все свои усилия для устойчивого развития мировой индустрии здравоохранения во благо всего человечества.
        <br>
        &emsp;&emsp;Международную Ассоциацию «Здоровье и Процветание» WHIEDA  возглавляет Президиум (председатель, исполнительный председатель и несколько заместителей председателя), ее основная структура состоит из Секретариата, Комитета по промышленно-экономическому развитию, Комитета по санитарному просвещению, Консультативного комитета отраслевых экспертов, Комитета по финансовому обеспечению производства и других комитетов. Также Ассоциация включает в себя региональные отделения, такие как Евразийская Ассоциация «Здоровье и Процветание», Африканская Ассоциация «Здоровье и Процветание» и Ассоциация стран Америки и Океании «Здоровье и Процветание».
      </p>
    </div>
    <div v-else-if="this.language !== 'zh_CN' && this.language !== 'ru_RU'" class="right-info p-scroll">
      <p>
        Tarptautinė organizacija "Sveikata ir Klestėjimas" (WHIEDA) yra sveikatos priežiūros pramonės tarptautinė organizacija. Jos būstinė yra Kinijoje.
        Vadovaujantis tarptautinio bendradarbiavimo iniciatyva "Vienas kelias, viena juosta" sveiko gyvenimo būdo kultūros plėtros srityje, WHIEDA siekia sukurti universalų verslo platformą bendradarbiavimui ir patirties mainams tarp įmonių, pramonės organizacijų, mokslinių tyrimų institutų, ekspertų ir mokslininkų sveikatos priežiūros srityje. Be to, WHIEDA asociacija pagrindiniu tikslu turi skatinti tarptautinius investicijų srautus į sveikatos priežiūros tarpregioninio bendradarbiavimo plėtrą, taip prisidedant prie "globalinės sveikatos pramonės revoliucijos". Asociacija deda visas pastangas siekdama tvaraus pasaulinės sveikatos pramonės plėtros visų žmonių labui.
        Tarptautinę asociaciją "Sveikata ir Klestėjimas" vadovauja prezidiumas (pirmininkas, vykdomasis pirmininkas ir kelios pirmininko pavaduotojų pareigybės), jos pagrindinė struktūra susideda iš Sekretariato, Pramonės ir ekonominės plėtros komiteto, Higienos švietimo komiteto, Konsultacinio pramonės ekspertų komiteto, Finansavimo gamybai komiteto ir kitų komitetų. Taip pat asociacija apima regioninius skyrius, tokius kaip Eurazijos asociacija "Sveikata ir Klestėjimas", Afrikos asociacija "Sveikata ir Klestėjimas" ir Amerikos bei Okeanijos šalių asociacija "Sveikata ir Klestėjimas".
      </p>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex"
export default {
  name: "AllianceIntroduce",
  computed:{
    ...mapGetters([
      'language'
     ])
  }
}

</script>

<style scoped lang="scss">
.left-image{
  width:30rem;
  height: 20rem;
  float: left;
  margin: 0 1rem;
}
.right-info {
  text-align: left;
  h3 {
    margin: 0;
    padding: 0 1rem;
  }
  p {
    line-height: 1.4rem;
    padding: 0 1rem;
  }
}
@media screen and (max-width: 991px){
  .left-image{
    width: 260px;
    height: 260px;
    margin: 0 1rem;
    overflow: hidden;
  }
  .p-scroll {
    width: 100%;
    height: 500px;
    overflow: scroll;
  }
  .right-info {
    p {
      padding-bottom: 120px;
    }
  }
}
</style>
